import "react-dice-complete/dist/react-dice-complete.css"

import React, { createRef, useEffect, useRef, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import SEO from "components/seo"
import { faBolt } from "@fortawesome/free-solid-svg-icons"
import styles from "./yazi-tura.module.css"
import useTranslations from "components/use-translations"

export default function RastgeleSayi() {
  const [count, setCount] = useState(0)

  const refs = useRef([])
  const audioRef = useRef({ current: null })
  const audioIsAvalible = audioRef.current && audioRef.current.volume

  useEffect(() => {
    if (audioIsAvalible) audioRef.current.src = "/sounds/coin.mp3"
    updateCount(1)
  }, [])

  function updateCount(value) {
    setCount(value)
    refs.current = refs.current.splice(0, value)
    for (let i = 0; i < value; i++) {
      refs.current[i] = refs.current[i] || createRef()
    }
    refs.current = refs.current.map(item => item || createRef())
  }
  const {
    cekilis: {
      yaziTuraSeoTitle,
      yaziTuraSeoDesc,
      yaziTuraAt,
      paraAt,
      atilacakParaSayisi,
    },
  } = useTranslations()
  return (
    <>
      <LayoutCekilis headerText={yaziTuraAt} type={RaffleTypes.YaziTura}>
        <SEO
          title={yaziTuraSeoTitle}
          description={yaziTuraSeoDesc}
          pathname="https://www.kodvizit.com/cekilis/yazi-tura"
          datePublished="2017-02-08T22:39:04+00:00"
          dateModified="2020-08-05T16:53:13+00:00"
        />
        <div className="d-flex flex-column ">
          <audio ref={audioRef} />

          <div
            className="text-center mb-3 font-weight-bold"
            style={{ fontSize: 16 }}
          >
            {atilacakParaSayisi} :
          </div>
          <div className="d-flex flex-row justify-content-center  ">
            <input
              style={{ maxWidth: 90 }}
              type="number"
              max="50"
              min="1"
              value={count}
              onChange={e => updateCount(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                if (audioIsAvalible) audioRef.current.src = "/sounds/coin.mp3"
                refs.current.forEach(e => {
                  e.current.classList.remove(styles.tails)
                  e.current.classList.remove(styles.heads)
                })
                audioRef.current.currentTime = 0
                audioRef.current.play()
                setTimeout(() => {
                  refs.current.forEach(e => {
                    const flipResult = Math.random()
                    e.current.classList.add(
                      flipResult < 0.5 ? styles.heads : styles.tails
                    )
                  })
                }, 100)
              }}
              className={`btn btn-info mb-4 mt-3`}
            >
              <FontAwesomeIcon icon={faBolt} className="mr-1" />
              <span>{paraAt}</span>
            </button>
          </div>
          {
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{ marginBottom: 120 }}
            >
              {refs.current.map((el, i) => (
                <div key={i} ref={refs.current[i]} className={styles.coin}>
                  <div className={styles.sidea} />
                  <div className={styles.sideb} />
                </div>
              ))}
            </div>
          }
        </div>
      </LayoutCekilis>
    </>
  )
}
